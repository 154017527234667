import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { rem, grid, flex, position, is } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import accomplishments from 'lib/weedmaps-accomplishments'
import Project from 'components/project'

const { colors, fonts } = theme
const { slate, weedmaps } = colors

const Accomplishments = styled.div`
  ${grid(2, 16, 32)}
  margin: ${rem(24)} 0;

  ${media.small`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.large`
    grid-template-columns: repeat(4, 1fr);
  `}
`
const AccomplishmentDef = styled.dl`
  ${flex('column', 'center', 'flex-start')}
  margin: 0;

  dt {
    font-family: ${fonts.secondary};
    width: 100%;

    span {
      border-radius: 100%;
      border: ${rem(2)} solid ${weedmaps};
      display: flex;
      margin: 0 auto ${rem(16)};
      opacity: 0;
      position: relative;
      transform: scaleX(0);
      width: ${rem(72)};

      :before {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      svg {
        ${position('absolute', '50%', 'auto', 'auto', '50%')}
        transform: translate(-50%, -50%);
      }
    }
  }

  dd {
    color: ${slate};
    width: 100%;
  }

  ${media.xsmall`
    dt span {
      border-width: ${rem(3)};
      width: 40%;
    }
  `}

  ${is('inView')`
    @keyframes accomplishmentSvgAppear {
      0% {
        filter: blur(${rem(4)});
        opacity: 0;
        transform: scaleX(0);
      }
      50% {
        filter: none;
        opacity: 1;
        transform: scaleX(1.1);
      }
      80% {
        opacity: 1;
        transform: scaleX(0.9);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
    dt span {
      animation: accomplishmentSvgAppear 750ms forwards ease;
      animation-delay: 100ms;
    }
    :nth-child(4n + 2) dt span {
      animation-delay: 200ms;
    }
    :nth-child(4n + 3) dt span {
      animation-delay: 300ms;
    }
    :nth-child(4n + 4) dt span {
      animation-delay: 400ms;
    }
  `}
`

const Accomplishment = ({ accomplishment }) => {
  const { title, description, Graphic } = accomplishment
  const [ref, inView] = useInView()

  return (
    <AccomplishmentDef ref={ref} inView={inView}>
      <dt>
        <span>
          <Graphic size="50%" fill={weedmaps} />
        </span>
        {title}
      </dt>
      <dd>{description}</dd>
    </AccomplishmentDef>
  )
}

export default () => (
  <Project name="Weedmaps">
    <div>
      <p>
        During my time with Weedmaps, I was a leader, mentor, and friend to
        everyone I had the pleasure of working with. I believe in the mission of
        connecting consumers and patients to the products they need, and the
        goal to bring legal cannabis to everyone. I worked on the consumer
        facing web products, built with React, Next.js, MobX, and Styled
        Components.
      </p>
      <p>
        An unexpected and massive layoff has left me unable to fully actualize
        all of my goals with Weedmaps, however I am proud what I was able to
        accopmlish during my tenure. Some of my noteable achievements are summed
        up below:
      </p>
    </div>
    <div>
      <Accomplishments>
        {accomplishments.map(accomplishment => (
          <Accomplishment
            key={accomplishment.title}
            accomplishment={accomplishment}
          />
        ))}
      </Accomplishments>
    </div>
  </Project>
)
