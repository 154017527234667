import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 474 474"
    fill={fill}
    {...rest}
  >
    <path d="M454.8 111.7c0-1.8-.4-3.6-1.2-5.3-1.6-3.4-4.7-5.7-8.1-6.4L241.8 1.2c-3.3-1.6-7.2-1.6-10.5 0L25.6 100.9c-4 1.9-6.6 5.9-6.8 10.4V362c0 4.6 2.6 8.8 6.8 10.8l205.7 99.7c.1 0 .1 0 .2.1.3.1.6.2.9.4.1 0 .2.1.4.1l.9.3c.1 0 .2.1.3.1.3.1.7.1 1 .2h.3c.4 0 .9.1 1.3.1s.9 0 1.3-.1h.3c.3 0 .7-.1 1-.2.1 0 .2-.1.3-.1l.9-.3c.1 0 .2-.1.4-.1.3-.1.6-.2.9-.4.1 0 .1 0 .2-.1l206.3-100c4.1-2 6.8-6.2 6.8-10.8V112c-.2-.1-.2-.2-.2-.3zM236.5 25.3l178.4 86.5-65.7 31.9-178.4-86.5 65.7-31.9zm0 173L58.1 111.8l85.2-41.3L321.7 157l-85.2 41.3zM42.8 131.1l181.7 88.1v223.3L42.8 354.4V131.1zm205.7 311.4V219.2l85.3-41.4v58.4c0 6.6 5.4 12 12 12s12-5.4 12-12v-70.1l73-35.4V354l-182.3 88.5z" />
  </svg>
)
