import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 512 512"
    fill={fill}
    {...rest}
  >
    <path d="M509.76 248.118c-.029-.046-.053-.094-.083-.141l-76.001-120.002a15.012 15.012 0 00-12.672-6.974H90.999a15.024 15.024 0 00-12.672 6.974c-.771 1.217-75.674 119.48-76.084 120.143-.084.136-.163.275-.243.414-3.4 5.912-2.355 13.273 2.335 18.018.019.02.036.041.056.06l241.003 241.003c.038.038.081.07.119.108 5.793 5.669 15.106 5.743 20.975 0 .039-.038.081-.069.119-.108L507.61 266.61c.019-.02.036-.041.056-.06a15.001 15.001 0 002.094-18.432zm-115.821-97.116l-49.063 78.5-58.875-78.5h107.938zm-77.937 90.001H196l60.001-80.001 60.001 80.001zm-90.001-90.001l-58.875 78.5-49.063-78.5h107.938zM90.915 164.169l48.021 76.834H42.253l48.662-76.834zM51.211 271.004H155.59l62.212 166.589L51.211 271.004zM256 454.13l-68.387-183.127h136.775L256 454.13zm38.201-16.537l62.212-166.589H460.79L294.201 437.593zm78.865-196.59l48.022-76.834 48.661 76.834h-96.683zM256.001 0c-8.284 0-15 6.716-15 15v60.001c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15zM195.357 68.617L152.93 41.19c-6.958-4.498-16.243-2.503-20.741 4.454-4.497 6.958-2.503 16.243 4.454 20.741l42.428 27.427c6.96 4.499 16.245 2.501 20.741-4.454 4.497-6.958 2.502-16.243-4.455-20.741zM379.814 45.644c-4.498-6.957-13.783-8.951-20.741-4.454l-42.428 27.427c-6.957 4.498-8.951 13.783-4.454 20.741 4.494 6.952 13.777 8.954 20.741 4.454l42.428-27.427c6.957-4.498 8.951-13.783 4.454-20.741z" />
  </svg>
)
