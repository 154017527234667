import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 32 32"
    fill={fill}
    {...rest}
  >
    <path d="M31.423 11.094l-15-7a1 1 0 00-.846 0l-15 7a1 1 0 000 1.812L6 15.437V23c0 2.851 4.3 5 10 5s10-2.149 10-5v-7.563l2-.934V20a1 1 0 002 0v-6.43l1.423-.664a1 1 0 000-1.812zM24 23c0 1.224-3.116 3-8 3s-8-1.776-8-3v-6.63l7.577 3.536a1 1 0 00.846 0l5-2.334a1 1 0 00-.846-1.812L16 17.9 3.364 12 16 6.1 28.635 12l-4.058 1.894a.99.99 0 00-.559 1.016A.9.9 0 0024 15z" />
  </svg>
)
