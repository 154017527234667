import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 512 512"
    fill={fill}
    {...rest}
  >
    <path d="M512 32H271V0h-30v32H0v30h31v300h138.507l-72 120H61v30h53.493l36-60H241v60h30v-60h90.507l36 60H451v-30h-36.507l-72-120H481V62h31zM168.493 422l36-60H241v60zm175.014 0H271v-60h36.507zM451 332H61V62h390z" />
    <path d="M116.606 297.607L196 218.213l60 60 105-105V212h30v-90h-90v30h38.787L256 235.787l-60-60L95.394 276.393z" />
  </svg>
)
