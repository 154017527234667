import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 512 512"
    fill={fill}
    {...rest}
  >
    <path d="M451.943 230.166H270.991v-86.667c10.592 2.314 24.462 4.465 39.461 4.465 19.81 0 41.576-3.752 60.301-15.835 48.36-31.204 54.447-103.769 54.682-106.843l.87-11.357-10.707-3.888c-2.899-1.052-71.524-25.411-119.883 5.795-19.075 12.308-31.569 31.05-39.725 49.426-8.156-18.375-20.65-37.118-39.725-49.426C167.906-15.37 99.282 8.989 96.384 10.041l-10.707 3.888.87 11.357c.235 3.074 6.322 75.639 54.681 106.843 18.729 12.085 40.489 15.835 60.301 15.835 14.999 0 28.869-2.151 39.461-4.466v86.668H60.039v90.049h32.19l30.024 191.768H389.73l30.024-191.768h32.19v-90.049zM311.981 41.043c18.158-11.716 42.037-12.033 58.87-10.238 8.935.953 16.924 2.593 22.959 4.091-1.123 6.118-2.921 14.073-5.734 22.605-5.301 16.078-15.431 37.704-33.589 49.421-18.157 11.717-42.035 12.035-58.87 10.238-8.975-.958-16.997-2.608-23.042-4.111 3.925-21.378 14.984-56.247 39.406-72.006zm-154.487 65.879c-18.158-11.717-28.288-33.343-33.589-49.421-2.826-8.572-4.628-16.562-5.75-22.689 21.094-5.232 57.423-9.525 81.845 6.231 18.158 11.717 28.288 33.343 33.588 49.421 2.826 8.572 4.628 16.562 5.75 22.689-21.094 5.234-57.422 9.526-81.844-6.231zm206.566 375.06H147.922l-25.328-161.768h266.794zm57.883-191.767H90.039v-30.049h331.904z" />
  </svg>
)
