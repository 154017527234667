import Atom from 'components/graphics/atom'
import Box from 'components/graphics/box'
import Blind from 'components/graphics/blind'
import Cap from 'components/graphics/cap'
import Cart from 'components/graphics/cart'
import Checklist from 'components/graphics/checklist'
import Code from 'components/graphics/code'
import Communication from 'components/graphics/communication'
import Coverage from 'components/graphics/coverage'
import Danger from 'components/graphics/danger'
import Design from 'components/graphics/design'
import Diamond from 'components/graphics/diamond'
import Gauge from 'components/graphics/gauge'
import Graph from 'components/graphics/graph'
import Grow from 'components/graphics/grow'
import Picture from 'components/graphics/picture'
import Polish from '../components/graphics/polish'
import Search from 'components/graphics/search'
import Shield from 'components/graphics/shield'
import Trophy from 'components/graphics/trophy'

export default [
  {
    title: 'Mentorship',
    description: 'Was a mentor for new hires and prolific code-reviewer.',
    Graphic: Grow,
  },
  {
    title: 'UI Components',
    description:
      'Helped build the UI library and pioneered an internal design system.',
    Graphic: Box,
  },
  {
    title: 'Modernization',
    description:
      'Helped transition the front-end codebase from Rails+Angular to React.',
    Graphic: Atom,
  },
  {
    title: 'Analytics',
    description: 'Was instrumental in setting up Optimizely and Segment.',
    Graphic: Graph,
  },
  {
    title: 'E-Commerce',
    description: 'Helped build the online ordering experience.',
    Graphic: Cart,
  },
  {
    title: 'Education',
    description: 'Lead internal education classes and presentations.',
    Graphic: Cap,
  },
  {
    title: 'Code Coverage',
    description: 'Was instrumental in bringing our unit test coverage up 97%.',
    Graphic: Checklist,
  },
  {
    title: 'Discovery',
    description: 'Helped build user flows for brands and listings.',
    Graphic: Search,
  },
  {
    title: 'Optimization',
    description: 'Refactored code to improve readability and performance.',
    Graphic: Gauge,
  },
  {
    title: 'Cutting Edge',
    description: 'Early adopter of new tech, sharing and leveling up peers.',
    Graphic: Diamond,
  },
  {
    title: 'Accessibility',
    description: 'Developed screen-reader friendly, tabbable, components.',
    Graphic: Blind,
  },
  {
    title: 'Top Contributor',
    description:
      'Personally conributed over 100,000 lines of code and 500+ pull requests.',
    Graphic: Trophy,
  },
  {
    title: 'Code Owner',
    description:
      'Was an owner of several repositories and maintained codebase health.',
    Graphic: Code,
  },
  {
    title: 'Communication',
    description: 'Was a liason between teams and champion for collaboration.',
    Graphic: Communication,
  },
  {
    title: 'Danger',
    description:
      'Implemented Danger plugin rules to reduce bugs during the PR process.',
    Graphic: Danger,
  },
  {
    title: 'Design',
    description:
      'Collaborated with the design team to develop optimal UX solutions.',
    Graphic: Design,
  },
  {
    title: 'Security',
    description:
      'Added and contributed to the audit-ci package in our repositories.',
    Graphic: Shield,
  },
  {
    title: 'Type Coverage',
    description:
      'Championed the team effort to add Flow type coverage across the codebase.',
    Graphic: Coverage,
  },
  {
    title: 'SVG',
    description:
      'Implemented SVG optimization with definitions and added animations.',
    Graphic: Picture,
  },
  {
    title: 'Styled Components',
    description:
      'Transitioned the app away from Foundation to a custom global style.',
    Graphic: Polish,
  },
]
