import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 490 490"
    fill={fill}
    {...rest}
  >
    <path d="M435.667 54.311c-7-7.1-18.4-7-25.5 0l-64 64c-79.3-36-163.9-27.2-244.6 25.5-60.1 39.2-96.6 88.5-98.1 90.6-4.8 6.6-4.6 15.6.5 22 34.2 42 70 74.7 106.6 97.5l-56.3 56.3c-7 7-7 18.4 0 25.5 3.5 3.5 8.1 5.3 12.7 5.3s9.2-1.8 12.7-5.3l356-355.9c7-7 7-18.4 0-25.5zm-235.2 209.7c-2.6-5.9-3.9-12.3-3.9-19 0-12.9 5-25.1 14.2-34.3 14.4-14.4 35.7-17.8 53.3-10.3l-63.6 63.6zm90.2-90.1c-32.7-21-76.8-17.2-105.3 11.3-16 16-24.7 37.2-24.7 59.7 0 16.4 4.7 32.1 13.4 45.6l-37.1 37.1c-32.5-18.8-64.5-46.6-95.6-82.9 13.3-15.6 41.4-45.7 79.9-70.8 66.6-43.4 132.9-52.8 197.5-28.1l-28.1 28.1zM486.067 233.611c-24.7-30.4-50.3-56-76.3-76.3-7.9-6.1-19.2-4.7-25.4 3.1-6.1 7.8-4.7 19.1 3.1 25.3 20.6 16.1 41.2 36.1 61.2 59.5-11.8 13.8-34.8 38.6-66 61.3-60.1 43.7-120.8 59.5-180.3 46.9-9.7-2.1-19.3 4.2-21.3 13.9-2.1 9.7 4.2 19.3 13.9 21.3 15.5 3.3 31.1 4.9 46.8 4.9 23.6 0 47.4-3.7 71.1-11.1 31.1-9.7 62-25.7 91.9-47.5 50.4-36.9 80.5-77.6 81.8-79.3 4.8-6.6 4.6-15.6-.5-22z" />
  </svg>
)
