import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 306 306"
    fill={fill}
    {...rest}
  >
    <path d="M302.961 68.721a7.5 7.5 0 000-10.606L247.043 2.197a7.5 7.5 0 00-10.606 0l-64.585 64.585a7.5 7.5 0 000 10.606 7.5 7.5 0 0010.606 0l59.282-59.282 45.311 45.311-20.959 20.959-16.505-16.505a7.5 7.5 0 00-10.606 0 7.5 7.5 0 000 10.606l16.505 16.505-17.912 17.912-16.506-16.504a7.5 7.5 0 00-10.606 0 7.5 7.5 0 000 10.606l16.505 16.505-15.304 15.304-96.226-96.226L92.248 19.39C84.291 11.433 73.712 7.051 62.46 7.051c-11.252 0-21.832 4.382-29.788 12.338L19.389 32.672c-16.425 16.425-16.425 43.15 0 59.576l23.189 23.189.01.009 40.299 40.299-80.69 80.692a7.5 7.5 0 000 10.606l55.917 55.917c1.464 1.464 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l68.12-68.12a7.5 7.5 0 000-10.606 7.5 7.5 0 00-10.606 0l-2.733 2.733-16.505-16.505a7.5 7.5 0 00-10.606 0 7.5 7.5 0 000 10.606l16.505 16.505-17.912 17.912-16.504-16.505a7.5 7.5 0 00-10.606 0 7.5 7.5 0 000 10.606l16.505 16.505-20.959 20.959-45.312-45.31 75.388-75.388 96.226 96.226c.044.044.093.082.138.125.115.109.23.216.352.318.078.065.158.127.238.188a6.55 6.55 0 00.647.446 5.955 5.955 0 00.691.376c.09.043.179.086.271.126.148.064.297.119.448.173.058.021.112.047.171.066l92.868 30.617a7.5 7.5 0 009.471-9.471l-30.617-92.869c-.021-.063-.049-.122-.071-.184a8.21 8.21 0 00-.165-.428 6.917 6.917 0 00-.134-.289 7.283 7.283 0 00-.185-.363c-.061-.112-.127-.219-.193-.327a8.755 8.755 0 00-.184-.288 7.844 7.844 0 00-.263-.358c-.058-.075-.115-.15-.176-.223a7.69 7.69 0 00-.331-.366c-.041-.042-.075-.088-.117-.129l-40.309-40.309 80.69-80.688zM208.94 253.965l45.026-45.026 22.145 67.172-67.171-22.146zm-13.916-7.297L53.186 104.831l-.01-.009-23.18-23.18c-10.576-10.577-10.576-27.786 0-38.363l13.283-13.283c5.124-5.124 11.935-7.945 19.181-7.945s14.058 2.821 19.181 7.945l17.886 17.886-25.271 25.27a7.5 7.5 0 000 10.606 7.498 7.498 0 0010.606 0l25.271-25.27 136.535 136.535-51.644 51.645z" />
  </svg>
)
