import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 478 478"
    fill={fill}
    {...rest}
  >
    <path d="M409.6 0c-9.426 0-17.067 7.641-17.067 17.067v62.344C304.667-5.656 164.478-3.386 79.411 84.479a221.44 221.44 0 00-62.344 154.454c0 9.426 7.641 17.067 17.067 17.067S51.2 248.359 51.2 238.933c.021-103.682 84.088-187.717 187.771-187.696a187.734 187.734 0 01138.442 60.976l-75.605 25.207c-8.954 2.979-13.799 12.652-10.82 21.606s12.652 13.799 21.606 10.82l102.4-34.133a17.069 17.069 0 0011.674-16.247v-102.4C426.667 7.641 419.026 0 409.6 0zM443.733 221.867c-9.426 0-17.067 7.641-17.067 17.067-.021 103.682-84.088 187.717-187.771 187.696a187.734 187.734 0 01-138.442-60.976l75.605-25.207c8.954-2.979 13.799-12.652 10.82-21.606-2.979-8.954-12.652-13.799-21.606-10.82l-102.4 34.133a17.069 17.069 0 00-11.674 16.247v102.4c0 9.426 7.641 17.067 17.067 17.067s17.067-7.641 17.067-17.067v-62.345c87.866 85.067 228.056 82.798 313.122-5.068a221.44 221.44 0 0062.344-154.454c.002-9.426-7.639-17.067-17.065-17.067z" />
  </svg>
)
