import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 490 490"
    fill={fill}
    {...rest}
  >
    <path d="M114.145 265.513c31.485 53.646 91.705 84.854 115.537 95.561v37.421c-50.78 5.331-88.629 36.887-88.629 76.192V490h207.883v-15.313c0-39.305-37.849-70.861-88.629-76.192v-37.409c23.814-10.697 84.084-41.916 115.579-95.594C483.122 209.118 477.329 63.754 477.04 57.544l-.72-14.562h-80.574V0H94.274v42.967h-80.59l-.72 14.562c-.303 6.226-6.098 151.627 101.181 207.984zm199.894 193.862H175.951c10.259-17.885 37.73-31.054 69.044-31.054s58.784 13.169 69.044 31.054zM446.308 73.607c-1.237 29.332-8.952 96.461-52.018 140.424a137.322 137.322 0 001.456-19.945V73.607h50.562zM124.883 30.625h240.223v163.461c0 82.06-95.994 129.605-120.126 140.247-24.148-10.627-120.096-57.943-120.096-140.247V30.625zM94.274 73.592v120.494c0 6.854.512 13.487 1.451 19.912C52.82 170.088 45.01 102.957 43.712 73.592h50.562z" />
  </svg>
)
