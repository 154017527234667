import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 512 512"
    fill={fill}
    {...rest}
  >
    <path d="M322 .01c-104.767 0-190 85.233-190 190 0 45.194 15.871 86.746 42.319 119.396l-26.604 26.604H91.147l-79.431 79.432C4.161 422.995 0 433.041 0 443.726s4.161 20.729 11.716 28.285l28.285 28.284c7.797 7.797 18.04 11.695 28.283 11.695s20.486-3.898 28.285-11.695L176 420.862v-56.568l26.604-26.604c32.65 26.448 74.202 42.319 119.396 42.319 104.767 0 190-85.233 190-190S426.767.01 322 .01zM136 404.294L68.284 472.01 40 443.726l67.716-67.716H136zm186-64.284c-82.71 0-150-67.29-150-150s67.29-150 150-150 150 67.29 150 150-67.29 150-150 150zm110-150h-40c0-38.598-31.402-70-70-70v-40c60.654 0 110 49.345 110 110z" />
  </svg>
)
