import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 502 502"
    fill={fill}
    {...rest}
  >
    <path d="M250.944 0L221.68 14.688l14.304 28.624 14.96-7.536 176 88v118.112a176.832 176.832 0 01-70.4 140.8l-105.6 79.2-105.6-79.2a176.832 176.832 0 01-70.4-140.8V123.776l17.936-8.96-14.304-28.624L42.944 104v137.888a208.992 208.992 0 0083.2 166.4l124.8 93.6 124.8-93.6a208.992 208.992 0 0083.2-166.4V104l-208-104z" />
    <path d="M193.047 28.898l14.31 28.62-28.62 14.311-14.31-28.62zM135.812 57.556l14.31 28.62-28.62 14.311-14.311-28.62zM130.944 306.592l120 73.856 120-73.856v-137.6l-120-73.888-120 73.84v137.648zm208-17.872l-72 44.368v-82.256l72-44.32v82.208zm-88-156.048l73.472 45.216-73.472 45.216-73.472-45.216 73.472-45.216zm-88 73.84l72 44.32v82.256l-72-44.304v-82.272z" />
  </svg>
)
