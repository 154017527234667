import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={typeof size === 'number' ? `${size}px` : size}
    height={typeof size === 'number' ? `${size}px` : size}
    viewBox="0 0 512 512"
    fill={fill}
    {...rest}
  >
    <path d="M254.111 197h-13.809V45c0-24.813-20.196-45-45.021-45H134.24c-24.823 0-45.02 20.187-45.02 45v152H75.049C30.188 197-4.474 236.08.471 280.258c.571 4.556 29.687 217.756 29.826 218.771A14.999 14.999 0 0045.159 512h240.163a15 15 0 0014.874-13.061L328.524 281.7c5.889-44.915-29.208-84.7-74.413-84.7zM119.22 45c0-8.271 6.738-15 15.021-15h61.041c8.282 0 15.021 6.729 15.021 15v152H119.22zM75.049 227h179.063c27.093 0 48.204 23.834 44.667 50.8l-14.208 108.966c-67.928 47.242-171.826 47.055-239.433-.844-8.059-59.064-14.492-106.26-14.862-109.093C27.433 250.959 47.519 227 75.049 227zM58.25 482l-7.875-57.691c68.217 36.323 159.121 37.367 229.236.483L272.151 482zM497.001 285h-46.576V181.223h2.9c6.62 0 12.458-4.34 14.364-10.681l27.13-90.223C497.71 70.706 490.503 61 480.455 61h-90.062c-10.039 0-17.258 9.697-14.364 19.319l27.13 90.223a15 15 0 0014.364 10.681h2.901V285h-44.506c-8.284 0-15 6.716-15 15v167c0 24.813 20.196 45 45.021 45h61.041c24.824 0 45.021-20.187 45.021-45V300c0-8.284-6.716-15-15-15zM410.567 91h49.714l-18.109 60.223h-4.259c-.811-.136-1.639-.223-2.488-.223s-1.677.087-2.488.223h-4.26zm71.434 376c0 8.271-6.738 15-15.021 15h-61.041c-8.282 0-15.021-6.729-15.021-15V315H482v152z" />
  </svg>
)
